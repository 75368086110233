body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game {
  background-color: #FEFE47;
  padding: 2em;
}

.card {

  width: 120px;
  height: 160px;
  padding: 5px;
  margin-bottom: 2em;
  cursor: pointer;
  user-select: none;
  text-align: left;
}

.card.card-selected {
  transform: scale(1.1);
}

.blackcard {
  background-color: black;
  color: white;
  border: 3px solid white;
}

.main-blackcard {

  width: auto;
  height: auto;
  min-height: 50px;
  max-width: 80%;
}

.hand {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.hand.disabled {
  opacity: 0.5;
}

.you-are-czar {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.whitecard {
  background-color: white;
  color: black;
  border: 3px solid black;
}

.yellowcard {
  background-color: #FEFE47;
  color: black;
  border: 3px solid black;
}

.answerhand {
  border: 3px solid black;
  width: 80%;
  margin: auto;
  padding: 10px;
  padding-top: 30px;
  margin-bottom: 20px;
}

.answerhand h3 {
  text-align: center;
}

.centeredtext {
  width: 100%;
  text-align: center;
}

#gamelink {
  color: #666666;
  cursor: pointer;
  user-select: none;
}

.question {
  font-size: 100px;
  text-align: center;
}