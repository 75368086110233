.modal-root {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.modal-bg {
  position: relative;
  width: 100%;
  height: 150%;
  background-color: #999999;
  opacity: 0.5;
  left: 0;
  top: 0;
}

.modal-fg {
  position: absolute;
  text-align: center;
  width: 50%;
  /* height: 50%; */
  background-color: #fefe47;
  border-radius: 5px;
  left: 50%;
  top: 50%;
  padding: 20px;
  transform: translate(-50%, -50%);

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
